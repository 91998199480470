import { render, staticRenderFns } from "./room_send3.vue?vue&type=template&id=1c423be3&scoped=true&"
import script from "./room_send3.vue?vue&type=script&lang=js&"
export * from "./room_send3.vue?vue&type=script&lang=js&"
import style0 from "./room_send3.vue?vue&type=style&index=0&id=1c423be3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c423be3",
  null
  
)

export default component.exports