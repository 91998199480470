<template>
  <div class="list" pullTips="false">
    <div class="item" v-for="(item, index) in lists" :key="index">
      <span
        @click="select(0, index * 2, item.a.code)"
        :class="[
          codes[0][index * 2] === item.a.code ? 'item-action' : 'item-code',
        ]"
        >{{ item.a.text }}</span
      >
      <span class="item-vs">vs</span>
      <span
        @click="select(0, index * 2 + 1, item.b.code)"
        :class="[
          codes[0][index * 2 + 1] === item.b.code ? 'item-action' : 'item-code',
        ]"
        >{{ item.b.text }}</span
      >
    </div>
  </div>
</template>

<style scoped>
.list {
  flex: 1;
}

.item {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 36px;
  padding-right: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f4f8fb;
}

.item-code,
.item-action {
  width: 152px;
  height: 58px;
  line-height: 58px;
  border-radius: 6px;
  font-size: 30px;
  text-align: center;
  color: #dc1323;
  background-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #e4e4e4;
}

.item-action {
  color: #ffffff;
  background-color: #dc1323;
  border-color: #dc1323;
}

.item-vs {
  width: 52px;
  height: 52px;
  line-height: 52px;
  border-radius: 26px;
  font-size: 28px;
  text-align: center;
  color: #dc1323;
  background-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #e4e4e4;
  margin-left: 32px;
  margin-right: 32px;
}
</style>

<script>
export default {
  name: 'RoomSendSscLh',

  props: {
    value: {
      //   type: Object,
      //   default: [],
    },
    maxnum: {
      default: 0,
    },
  },

  data() {
    return {
      lists: [
        {
          a: {
            code: 'Chục nghìn vị so với Nghìn vị',
            text: 'Chục nghìn vị',
          },
          b: {
            code: 'Nghìn vị so với Chục nghìn vị',
            text: 'Nghìn vị',
          },
        },
        {
          a: {
            code: 'Nghìn vị',
            text: 'Chục nghìn vị',
          },
          b: {
            code: 'Trăm vị so với Chục nghìn vị',
            text: 'Trăm vị',
          },
        },
        {
          a: {
            code: 'Chục nghìn vị so với Chục vị',
            text: 'Chục nghìn vị',
          },
          b: {
            code: 'Chục vị so với Chục vị ngàn',
            text: 'Chục vị',
          },
        },
        {
          a: {
            code: 'Chục nghìn vị so với Đơn vị',
            text: 'Chục nghìn vị',
          },
          b: {
            code: 'Đơn vị so với Chục nghìn vị',
            text: 'Đơn vị',
          },
        },
        {
          a: {
            code: 'Nghìn vị so với Trăm vị',
            text: 'Nghìn vị',
          },
          b: {
            code: 'Hàng Trăm vị so với hàng Nghìn vị',
            text: 'Trăm vị',
          },
        },
        {
          a: {
            code: 'Nghìn vị so với Chục vị',
            text: 'Nghìn vị',
          },
          b: {
            code: 'Chục vị so với ngàn',
            text: 'Chục vị',
          },
        },
        {
          a: {
            code: 'Nghìn vị so với Đơn vị',
            text: 'Nghìn vị',
          },
          b: {
            code: 'Đơn vị so với một ngàn',
            text: 'Đơn vị',
          },
        },
        {
          a: {
            code: 'Trăm vị so với Chục vị',
            text: 'Trăm vị',
          },
          b: {
            code: 'Chục vị so với một Trăm vị',
            text: 'Chục vị',
          },
        },
        {
          a: {
            code: 'Hàng Trăm vị so với Đơn vị',
            text: 'Trăm vị',
          },
          b: {
            code: 'Đơn vị so với một Trăm vị',
            text: 'Đơn vị',
          },
        },
        {
          a: {
            code: 'Chục vị so với Đơn vị',
            text: 'Chục vị',
          },
          b: {
            code: 'Đơn vị so với Chục vị',
            text: 'Đơn vị',
          },
        },
      ],
      codes: [[]],
    }
  },

  mounted() {},

  watch: {
    value(val) {
      if (val === '') {
        const newValue = []
        for (let i = 0; i < this.codes.length; i++) {
          newValue.push([])
        }
        this.codes = newValue
        this.$emit('input', this.codes)
      }
    },
  },

  methods: {
    select(pos, num, code) {
      this.$set(this.codes[pos], num, this.codes[pos][num] !== code ? code : '')
      this.$emit('input', this.codes)
    },
  },
}
</script>
