<template>
  <div class="order">
    <!-- 导航栏 -->
    <!-- :title="title ? title : this.lottery.title" -->
    <van-nav-bar
      class="nav-bar"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <i
        slot="left"
        class="iconfont icon-jiantou1"
        @click="$router.back()"
        v-if="isShowBack"
      ></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'">{{
          lottery.title
        }}</span>
      </template>
      <template #right>
        <van-popover
          v-model="showPopover"
          theme="dark"
          trigger="click"
          :actions="actions"
          placement="bottom-end"
          @select="HanddleClickitem"
        >
          <template #reference>
            <button>Chi tiết</button>
          </template>
        </van-popover>
      </template>
    </van-nav-bar>
    <div class="order-title-new">
      <div class="order-title-label-list">
        <div class="order-title-label-item">
          <span>mở kỳ tiếp theo</span>
          <div>
            <van-count-down
              v-if="lotteryEnd > 0"
              :time="lotteryEnd * 1000"
              format="mm : ss"
            />
            <p v-else>về để bắt đầu</p>
          </div>
        </div>
        <div class="order-title-label-item">
          <span>số tiền đặt cược</span>
          <p>₫{{ parseFloat(totalMoney).toFixed(2) }}</p>
        </div>
        <div class="order-title-label-item">
          <span>số dư tài khoản</span>
          <p>₫{{ parseFloat(userMoney).toFixed(2) }}</p>
        </div>
      </div>
    </div>
    <div class="lottery-list-item">
      <van-collapse v-model="activeNames" :border="false">
        <van-collapse-item name="1" :border="false">
          <template #title>
            <div class="item">
              <span class="number">Lượt xổ {{ drawObj.expect }}Đang chờ</span>
              &nbsp;
              <span class="number">{{
                drawObj.myNum ? drawObj.myNum : 'Lượt xổ số đang tiến hành'
              }}</span>
            </div>
          </template>
          <template #default>
            <div class="list">
              <van-list
                finished-text="kết nối internet"
                loading-text="Đang tải..."
              >
                <van-cell
                  v-for="item in historyLists"
                  :key="item.id"
                  :border="false"
                >
                  <template #default>
                    <div class="item item2">
                      <div class="item-history-left">
                        <span class="number">{{ item.expect }}Đang chờ</span>
                        <div class="number-open-code-box">
                          <span
                            class="number"
                            v-for="(code, index) in openDwd(item.open)"
                            :key="index"
                            >{{ code }}</span
                          >
                        </div>
                      </div>
                      <span class="history-right">{{ openZh(item.open) }}</span>
                    </div>
                  </template>
                </van-cell>
              </van-list>
            </div>
          </template>
        </van-collapse-item>
      </van-collapse>
    </div>

    <!-- 聊天室区域 -->
    <ChatRoom2
      ref="chatRoom"
      class="chatRoom"
      :lotteryType="lottery"
      :userid="userInfo.id"
      :gameType="'all'"
    ></ChatRoom2>

    <!-- 新的确认投注窗口 -->
    <div v-if="lotteryConfirmShowNew" class="lottery-confirm-new">
      <div class="lottery-confirm-main-new">
        <div class="confirm-head-new">
          <span
            class="confirm-bar-new"
            :style="{ backgroundColor: `${settingConfirmInfo.styleColor}` }"
            >{{ settingConfirmInfo.lotteryConfirmTitle }}</span
          >
        </div>
        <div class="lottery-confirm-mian-cont">
          <div class="lottery-confirm-mian-cont-select">
            <span class="lottery-confirm-mian-cont-select-text"
              >số lượng có thể lựa chọn(₫)</span
            >
            <div class="lottery-confirm-mian-cont-select-kk">
              <span
                v-for="(item, index) in lotteryConfirmKK"
                :key="index"
                class="lottery-confirm-mian-cont-select-key"
                :class="{
                  'lottery-confirm-mian-cont-select-key-on':
                    moneyShow == item.key,
                }"
                @click="handleSelectConfirmMoney(item)"
                >{{ item.key }}</span
              >
            </div>
          </div>
          <div class="lottery-confirm-mian-cont-input-box">
            <span class="lottery-confirm-mian-cont-select-text"
              >Vui lòng nhập số tiền</span
            >
            <div class="lottery-confirm-mian-cont-input">
              <span class="cont-input-text">₫&nbsp;</span>
              <!-- 请输入金额 -->
              <input
                class="cont-input"
                type="number"
                placeholder="Vui lòng nhập số tiền"
                v-model="moneyShow"
                :autofocus="autofocus2"
                @keyboard="keyboardStatus"
              />
            </div>
          </div>
          <div class="lottery-confirm-mian-money-show-box">
            <div class="lottery-confirm-mian-cont-select-text">
              Tổng số tiền là
            </div>
            <div class="lottery-confirm-mian-cont-show-money">
              ₫&nbsp;{{ moneyShowTofixed2(moneyShow) }}
            </div>
          </div>
          <div class="lottery-confirm-mian-money-checkbox"></div>
          <div class="lottery-confirm-mian-btn-box">
            <button
              class="lottery-confirm-mian-btn-box-colse"
              text="CANCEL"
              backgroundColor="#ededed"
              color="#999999"
              fontSize="36"
              @click="handleCloseConfirmShowBox"
            >
              Hủy bỏ
            </button>
            <button
              class="lottery-confirm-mian-btn-box-confirm"
              :style="{ backgroundColor: `${settingConfirmInfo.styleColor}` }"
              text="CONFIRM"
              fontSize="36"
              @click="handleConfirm"
            >
              xác nhận
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div
        class="footer-chat"
        @click="chatShow = true"
        v-if="lottery.room_status === 1"
      >
        <!-- <img src="@/assets/images/new-img/lt.png" alt="" /> -->
        <img src="@/assets/images/room/liaotian.png" alt="" />
        trò chuyện
      </div>
      <div
        class="footer-play"
        :class="{ 'yellow-color': !$isLinkGame, 'black-bc': !$isLinkGame }"
        @click="handleRoomSeed"
        :style="lottery.room_status === 1 ? 'width: 66.66%;' : 'width: 100%;'"
      >
        Bắt đầu đặt cược
      </div>
    </div>

    <div class="chat" v-show="chatShow">
      <van-button type="danger" size="small" @click="chatShow = false"
        >quay lại</van-button
      >
      <van-button
        type="info"
        size="small"
        :disabled="text === ''"
        :class="{ gray: text === '' }"
        @click="sendMessage"
        >gửi</van-button
      >
      <input type="text" v-model="text" />
    </div>

    <RoomSend2
      v-if="showRoomSeed"
      :type="lottery.type"
      :hall_methods="hall ? hall_methods : {}"
      @clickClose="showRoomSeed = false"
      @clickSend="lotterySetting"
    ></RoomSend2>

    <!--确认投注窗口-->
    <div v-if="lotteryConfirmShow" class="lottery-confirm">
      <div class="lottery-confirm-main">
        <div class="confirm-head">
          <p class="confirm-bar">Thiết lập lệnh đặt cược</p>
          <van-icon
            name="clear"
            class="confirm-close"
            @click="lotteryConfirmShow = false"
          />
        </div>
        <div class="confirm-odds">
          <span class="confirm-odds-title">tỷ lệ cược:</span>
          <!-- <marquee class="confirm-odds-info" :text="settingOdds"></marquee> -->
          <van-notice-bar class="confirm-odds-info" :text="settingOdds" />
        </div>
        <div class="confirm-inbox">
          <span class="confirm-inbox-text">số tiền đặt cược duy nhất:</span>
          <div class="confirm-inbox-input">
            <input
              :hideDoneButton="false"
              class="confirm-inbox-inputi"
              type="number"
              v-model="settingMoney"
            />
          </div>
          <span
            :class="[
              settingType === 'yuan'
                ? 'confirm-inbox-btn-act'
                : 'confirm-inbox-btn',
            ]"
            @click="settingType = 'yuan'"
            >đồng</span
          >
          <!-- <span
            :class="[
              settingType === 'jiao'
                ? 'confirm-inbox-btn-act'
                : 'confirm-inbox-btn',
            ]"
            @click="settingType = 'jiao'"
            >มุม</span
          > -->
        </div>
        <div class="confirm-item">
          <span class="confirm-item-title">cược số:</span>
          <span class="confirm-item-value">{{ settingNumber }}</span>
          <span class="confirm-item-title">Ghi chú</span>
          <marquee
            v-if="settingNumberInfo !== ''"
            class="confirm-item-subtitle"
            :text="'(' + settingNumberInfo + ')'"
          ></marquee>
        </div>
        <div class="confirm-item">
          <span class="confirm-item-title">Tổng:</span>
          <span class="confirm-item-value">{{
            calcMoney(settingNumber, settingMoney, settingType)
          }}</span>
          <span class="confirm-item-title">đồng</span>
        </div>
        <div class="confirm-item">
          <span class="confirm-item-title">Số tiền thắng tối đa:</span>
          <span class="confirm-item-value">{{
            calcMoney(settingOneOrderMoney, settingMoney, settingType)
          }}</span>
          <span class="confirm-item-title">đồng</span>
        </div>
        <div class="confirm-foot">
          <button
            v-if="canAuto(lottery.type) && lottery.addto && !hall"
            class="confirm-foot-button1"
            @click="lotterySubmit(true)"
            text="การพนันตามตัวเลข"
          ></button>
          <button class="confirm-foot-button2" @click="lotterySubmit(false)">
            đặt cược ngay bây giờ
          </button>
        </div>
      </div>
    </div>

    <van-loading v-if="isshowLoading" type="spinner" />
    <van-popup
      v-model="isShowPopup"
      position="bottom"
      :style="{ height: '30%' }"
    >
    </van-popup>
  </div>
</template>

<script>
import * as RongIMLib from '@rongcloud/imlib-v4'
import RoomSend from './room_send2.vue'
import RoomSend2 from './room_send3.vue'
import ChatRoom2 from './Chat_room2.vue'
import {
  getLotteryList,
  getLotteryInfo,
  getLotteryPost,
} from '@/api/lottery.js'
import { runNum, formatMobile, jsonStringify } from '@/utils/method.js'
import { userinfo } from '@/api/use.js'
import { saveMessage, historyMessage } from '@/api/message.js'
import { NotLoginLinkPage } from '@/utils/storage'
import { count, delLast, last, each } from '@/utils/unique'
import request from '@/utils/request'
import { Dialog } from 'vant'

export default {
  name: '',
  components: {
    RoomSend,
    RoomSend2,
    ChatRoom2,
  },
  data() {
    return {
      title: '',
      isShowPopup: false,
      showPopover: false,
      actions: [
        { text: 'Kiểu chơi' },
        // { text: 'tỷ lệ cược' },
        { text: 'Dịch vụ khách hàng' }, // 没有客服
        { text: 'Lịch sử đặt cược' },
      ],
      activeNames: ['0'],
      list: [1, 2, 3],
      IM: null,
      loading: false,
      finished: false,
      showRoomSeed: false,
      checked: true,
      isshowLoading: false, // 控制是否显示加载
      lottery: {}, // 跳转过来接受参数
      drawObj: {}, // 第一条开奖数据
      historyLists: [], // 开奖列表数据
      lotteryIng: [], // 正在开奖的数据
      lotteryEnd: 0, // 离开奖的时间
      userMoney: 0.0, // 用户余额
      totalMoney: '0.00',
      moneyShow: '10', // 显示的金额
      mtype: this.settingType, // 'yuan'
      lotteryConfirmShowNew: false,
      hall: false,
      hall_field: 0,
      hall_show: false,
      userInfo: {},
      settingOdds: '',
      settingNumber: 0,
      settingNumberInfo: '',
      settingOneOrderMoney: 0,
      settingOneMethodMoney: {},
      settingSelectCodeString: {},
      settingConfirmInfo: {},
      newMessage: {},
      messageLists: [], // 保存历史聊天记录
      lotteryConfirmKK: [
        {
          key: '10',
          isSelect: true,
        },
        {
          key: '100',
          isSelect: false,
        },
        {
          key: '1000',
          isSelect: false,
        },
        {
          key: '10000',
          isSelect: false,
        },
      ],
      autofocus2: false,
      isCheck: true,
      chatShow: false,
      text: '',
      serviceURL: '',
      lotteryConfirmShow: false,
      settingMoney: 10,
      settingType: 'yuan',
      sendLoadNum: 0,
      lotteryList: [],
      appLinkClick: false,
    }
  },
  mounted() {
    this.hall = runNum(this.lottery.hall) === 1
    this.hall_field = runNum(this.lottery.hall_field)
    if (this.hall) {
      this.hall_show = true
    }
    this.lotteryEndWatch()
    this.getLotteryList()
    this.getHistoryMessage()
    this.getLotteryInfo()
    this.lotteryListWatch()
    this.getAllInfo()
    this.getService()
  },
  created() {
    // this.initRong()
    // this.getUserInfo()
    this.lottery = this.$store.state.lottery
    console.log(this.$store.state.lottery)
    if (this.$route.query.key) {
      if (this.$route.query.key !== localStorage.getItem('APP_LINK_KEY')) {
        localStorage.setItem('APP_LINK_KEY', this.$route.query.key)
        if (this.$route.query.type) {
          this.judgment()
          this.getLottery()
        } else {
          Dialog.alert({
            title: 'Lưu ý',
            message:
              'Đánh máy, vui lòng liên hệ với dịch vụ. Bạn vẫn chưa đăng ký. Hãy bắt đầu.',
          }).then(() => {})
        }
      } else {
        if (!this.$store.state.user) {
          this.judgment()
        }
        this.getLottery()
        this.appLinkClick = true
      }
    } else {
      this.lottery = this.$store.state.lottery
      this.initRong()
      this.getUserInfo()
    }
  },

  watch: {
    lotteryEnd: function (val) {
      if (val < 0 && this.lotteryIng.expect !== '') {
        const also = 10 - Math.abs(val % 10)
        if (also === 2) {
          this.getLotteryInfo()
          this.getLotteryList()
        }
      }
    },
  },
  methods: {
    async getService() {
      const {
        data: { data, ret },
      } = await request({
        method: 'get',
        url: 'other/config',
        params: {
          act: 'system',
          refresh: this.isLoading === true ? 1 : 0,
        },
      })
      if (ret === 1) {
        this.serviceURL = data.service_url
      }
    },
    /**
     * 开始投注
     * @param isAuto
     */
    lotterySubmit(isAuto) {
      if (runNum(this.settingMoney) <= 0) {
        this.$toast('Vui lòng nhập số tiền đặt cược chính xác.')
        return
      }
      // eslint-disable-next-line camelcase
      let submit_money = this.settingNumber * this.settingMoney
      if (this.settingType === 'jiao') {
        // eslint-disable-next-line camelcase
        submit_money *= 0.1
      }
      if (runNum(this.lotteryIng.expect) === 0) {
        this.$toast({
          message: 'đang trong cuộc xổ số Vui lòng chờ...',
        })
        return false
      }
      if (isAuto === true) {
        this.lotteryPlusShow = true
        return
      }

      if (this.sendLoadNum > 0) {
        return
      }
      this.sendLoadNum = count(this.settingSelectCodeString)
      each(this.settingSelectCodeString, (method, codeString) => {
        this.lotteryPost('', '', method, codeString)
      })
    },

    /**
     * 开始追号投注
     * @param data
     */
    lotteryPlusSend(data) {
      // eslint-disable-next-line camelcase
      let submit_money = data.allMoney
      if (this.settingType === 'jiao') {
        // eslint-disable-next-line camelcase
        submit_money *= 0.1
      }
      if (!this.checkMoney(submit_money)) {
        return
      }
      if (runNum(this.lotteryIng.expect) === 0) {
        this.$toast({
          gravity: 'middle',
          message: 'đang trong cuộc xổ số Vui lòng chờ...',
        })
        return
      }
      this.lotteryPlusShow = false
      this.lotteryPost(data.lists, data.autoStop)
    },

    /**
     * 提交投注
     * @param plusLists
     * @param plusAutoStop
     */
    async lotteryPost(plusLists, plusAutoStop, method, codeString) {
      this.$toast.loading({ forbidClick: true, loadingType: 'spinner' })
      const {
        data: { ret, data, msg },
      } = await getLotteryPost({
        type: this.lottery.type,
        expect: this.lotteryIng.expect,
        method,
        code: codeString
          .replace(/Tài/g, '大')
          .replace(/Xỉu/g, '小')
          .replace(/Lẻ/g, '单')
          .replace(/Chẵn/g, '双'),
        money: this.settingMoney,
        mtype: this.settingType,
        // 追号参数
        pluslists: jsonStringify(plusLists),
        plusautostop: plusAutoStop === true ? 1 : 0,
        // 庄家模式
        hall: this.hall ? 1 : 0,
        hall_field: this.hall_field,
        method_money: this.settingOneMethodMoney[method],
      })
      this.showRoomSeed = false
      this.lotteryConfirmShowNew = false
      if (ret === 1) {
        this.sendLoadNum--
        if (this.sendLoadNum <= 0) {
          this.lotteryConfirmShow = false
          this.settingType = 'yuan'
        }
        this.userMoney = data.after_money
        this.totalMoney = data.after_totalMoney

        const message = {
          type: 'lottery',
          userid: this.userInfo.id,
          username: this.userInfo.username,
          userphone: formatMobile(this.userInfo.userphone),
          userimg: this.userInfo.userimg,
          lottery: {
            expect: data.expect,
            method: data.method,
            number: data.num,
            code: data.code,
            totalMoney: runNum(data.totalMoney, 2),
          },
        }
        const IM = this.IM
        // 注: im 实例通过 RongIMLib.init 获取(单mộtสถานที่页面仅需初始化一次)
        var chatRoom = IM.ChatRoom.get({
          id: this.lottery.roomid,
        })
        chatRoom
          .send({
            messageType: RongIMLib.MESSAGE_TYPE.TEXT,
            content: message,
            isPersited: true, // 是否存储在服务端,默认为 true
            isCounted: true, // 是否计数. 计数消息接收端接收后未读数加 1，默认为 true
            pushContent: 'user gửi tin nhắn', // Push 显示内容
            pushData: 'Push Thông tin bổ sung khi được thông báo', // Push Thông tin bổ sung khi được thông báo, 可不填
            isStatusMessage: false, // 设置为 true 后 isPersited 和 isCounted 属性失效
          })
          .then(function (message) {
            // console.log('发送 s:person 消息成功', message)
          })

        // 保存下注记录
        const res = await saveMessage({
          type: this.$route.query.type || this.lottery.type,
          message: jsonStringify(message),
        })
        if (res.data.ret === 1) {
          this.$refs.chatRoom.Message = message
          this.$toast.clear()
        }
      } else if (ret === 0) {
        this.$toast(msg)
        this.sendLoadNum = 0
      } else {
        this.$toast(msg)
        this.sendLoadNum = 0
      }
    },

    calcMoney(value, money, type) {
      let m = value * money
      if (type === 'jiao') {
        m *= 0.1
      }
      return runNum(m, 2)
    },
    /**
     * 是否支持追号
     * @param type
     * @returns {boolean}
     */
    canAuto(type) {
      switch (type) {
        case 'cqssc':
        case 'xjssc':
        case 'tjssc':
        case 'sfssc':
        case 'txffc':
        case 'sfpk10':
        case 'sf11x5':
        case 'jx11x5':
        case 'sd11x5':
        case 'sf3d':
        case 'bjsc':
        case 'xyft':
        case 'pcdd':
        case 'jnd28':
        case 'js6hc':
        case 'xy28':
        case 'ffk3':
          return true

        default:
          return false
      }
    },
    // 初始化融云
    initRong() {
      this.IM = RongIMLib.init({
        appkey: 'e5t4ouvpe6y7a', // 融云appkey
      })
    },

    async getUserInfo() {
      const {
        data: { ret, data },
      } = await userinfo()
      if (ret === 1) {
        this.userInfo = data
        window.localStorage.userInfo = JSON.stringify(data)
      }
    },
    //   离开奖结束倒计时lotteryEnd的处理
    lotteryEndWatch() {
      var that = this
      const timer1 = window.setInterval(() => {
        setTimeout(() => {
          that.lotteryEnd--
        }, 0)
      }, 1000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer1)
      })
    },

    //   获取开奖列表
    lotteryListWatch() {
      var that = this
      const timer2 = window.setInterval(() => {
        setTimeout(() => {
          that.getLotteryList()
        }, 0)
      }, 2000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer2)
      })
    },

    //   获取开奖数据列表
    async getLotteryList() {
      const {
        data: { ret, data },
      } = await getLotteryList({
        type: this.lottery.type || this.$route.query.type,
        page: 1,
      })
      if (ret === 1) {
        this.historyLists = data.lists
        // console.info('historyLists', this.historyLists)
        if (this.historyLists.length) {
          this.drawObj = this.historyLists[0]
          // this.drawObj = this.historyLists[1]
          // myNum 最后一位数
          this.drawObj.myNum = this.drawObj.code
            ? this.drawObj.code.substr(this.drawObj.code.length - 1, 1)
            : ''
          // this.historyLists.shift()
          // this.historyLists.shift()
        }

        this.historyLists.forEach((_) => {
          _.myNum = _.code ? _.code.substr(_.code.length - 1, 1) : ''
        })
      }
    },

    // 获取正在开奖数据
    async getLotteryInfo() {
      const {
        data: { data, ret },
      } = await getLotteryInfo({
        type: this.$route.query.type || this.lottery.type,
      })
      if (ret === 1) {
        if (data.ing.status === '待开奖') {
          this.lotteryIng = data.ing
          this.lotteryEnd =
            data.ing.endtime - Math.round(new Date().getTime() / 1000)
        }
        this.userMoney = data.money
        // console.log(data)
      }
    },

    // 获取开奖code的最后一位
    lastNumber(code) {
      var str = code.toString()
      var lastNumber = parseInt(str.substr(str.length - 1, 1))
      return lastNumber
    },
    /**
     * 新下注
     */
    handleConfirm() {
      const num = parseInt(this.userMoney) - runNum(this.moneyShow)
      if (num < 0.0) {
        this.$toast({
          message: 'thiếu cân bằng!',
          position: 'bottom',
        })
        this.showRoomSeed = false
        this.lotteryConfirmShowNew = false
        return
      }
      this.isshowLoading = true
      if (runNum(this.moneyShow) <= 0) {
        this.$toast('Vui lòng nhập số tiền đặt cược chính xác.')
        return
      }
      if (runNum(this.lotteryIng.expect) === 0) {
        this.$toast({
          //   gravity: 'middle',
          message: 'đang trong cuộc xổ số Vui lòng chờ...',
        })
        return false
      }
      this.lotteryPostNew()
    },
    async lotteryPostNew() {
      this.$toast.loading({ forbidClick: true, loadingType: 'spinner' })
      const {
        data: { ret, data, msg },
      } = await getLotteryPost({
        type: this.lottery.type,
        expect: this.lotteryIng.expect,
        method: this.settingConfirmInfo.method, // hl-dwd/hl-sb
        code: this.settingConfirmInfo.code,
        money: this.moneyShow,
        mtype: 'yuan', // 'yuan'
      })
      this.showRoomSeed = false
      this.lotteryConfirmShowNew = false
      if (ret === 1) {
        this.showRoomSeed = false
        this.lotteryConfirmShowNew = false
        this.settingType = 'yuan'
        this.userMoney = data.after_money
        this.totalMoney = data.after_totalMoney
        const message = {
          type: 'lottery',
          userid: this.userInfo.id,
          username: this.userInfo.username,
          userphone: formatMobile(this.userInfo.userphone),
          userimg: this.userInfo.userimg,
          lottery: {
            expect: data.expect,
            method: data.method,
            number: data.num,
            code: data.code,
            totalMoney: runNum(data.totalMoney, 2),
          },
        }
        const IM = this.IM
        // 注: im 实例通过 RongIMLib.init 获取(单mộtสถานที่页面仅需初始化一次)
        var chatRoom = IM.ChatRoom.get({
          id: this.lottery.roomid,
        })
        chatRoom
          .send({
            messageType: RongIMLib.MESSAGE_TYPE.TEXT,
            content: message,
            isPersited: true, // 是否存储在服务端,默认为 true
            isCounted: true, // 是否计数. 计数消息接收端接收后未读数加 1，默认为 true
            pushContent: 'user gửi tin nhắn', // Push 显示内容
            pushData: 'Push Thông tin bổ sung khi được thông báo', // Push Thông tin bổ sung khi được thông báo, 可不填
            isStatusMessage: false, // 设置为 true 后 isPersited 和 isCounted 属性失效
          })
          .then(function (message) {
            // console.log('发送 s:person 消息成功', message)
          })

        // 保存下注记录
        const res = await saveMessage({
          type: this.lottery.type,
          message: jsonStringify(message),
        })
        if (res.data.ret === 1) {
          this.$refs.chatRoom.Message = message
          this.$toast.clear()
        }
      } else if (ret === 0) {
        this.$toast(msg)
      }
    },

    lotterySetting(info) {
      console.info('info', info)
      this.getMethodInfo = info.selectType
      this.settingOdds = info.odds
      this.settingNumber = info.number
      this.settingNumberInfo = info.numberInfo
      this.settingOneOrderMoney = info.oneOrderMoney
      this.settingOneMethodMoney = info.oneMethodMoney
      this.settingSelectCodeString = info.selectCodeString

      /**
       * 以下为二次开发添加内容
       */
      if (this.lottery.type === 'hl') {
        this.lotteryConfirmKK.forEach((_) => {
          _.isSelect = false
        })
        this.lotteryConfirmKK[0].isSelect = true
        const colorList = [
          {
            key: 'green',
            // value: 'Tham gia Green',
            value: 'gấu trúc',
            color: '#13cc00',
            codeKey: 'màu xanh lá',
          },
          {
            key: 'violet',
            // value: 'Join Violet',
            value: 'Rồng',
            color: '#b400ff',
            codeKey: 'màu tía',
          },
          {
            key: 'red',
            // value: 'Join Red',
            value: 'con hổ',
            color: '#ff0000',
            codeKey: 'màu đỏ',
          },
        ]
        const ind = colorList.findIndex((_) => {
          return _.key === info.key
        })
        this.settingConfirmInfo = info
        this.settingConfirmInfo.lotteryConfirmTitle =
          info.selectType === 'qiuqiu' ? 'con số' : colorList[ind].value
        this.settingConfirmInfo.styleColor =
          info.selectType === 'qiuqiu' ? '#009cff' : colorList[ind].color
        this.settingConfirmInfo.code =
          info.selectType === 'qiuqiu' ? info.key : colorList[ind].codeKey
        this.settingConfirmInfo.method =
          info.selectType === 'qiuqiu' ? 'hl-dwd' : 'hl-sb'

        this.lotteryConfirmKK.forEach((_) => {
          if (_.isSelect) {
            this.moneyShow = _.key * 1
          }
        })
        this.lotteryConfirmShowNew = true
      } else {
        this.lotteryConfirmShow = true
      }
    },
    /**
     * money显示保留两位小数
     */
    moneyShowTofixed2(val) {
      return (val * 1).toFixed(2)
    },
    handleCheck() {
      this.isCheck = !this.isCheck
    },
    handleCloseConfirmShowBox() {
      this.lotteryConfirmShowNew = false
      this.autofocus2 = false
    },
    /// 监听键盘收回
    keyboardStatus(obj) {
      // console.info(obj)
      if (obj.isShow === false) {
        this.autofocus = false
        this.autofocus2 = false
      }
    },

    // 点击切换金额
    handleSelectConfirmMoney(item) {
      this.moneyShow = item.key
    },

    formatMobile(phone) {
      return phone.substring(0, 3) + '****' + phone.substring(phone.length - 4)
    },

    // 获取历史聊天记录
    async getHistoryMessage() {
      this.$toast('Loading...')
      const {
        data: { ret, data },
      } = await historyMessage({
        type: this.$route.query.type || this.lottery.type,
      })
      if (ret === 1) {
        for (var obj of data) {
          if (obj !== 'Array') {
            this.messageLists.push(JSON.parse(obj))
          }
        }
        this.$refs.chatRoom.messageLists = this.messageLists
        this.$toast.clear()
      }
    },

    async sendMessage() {
      this.chatShow = false
      var speakText = this.text.trim()
      if (this.$store.state.user === '') {
        this.$toast(
          'Thông tin đăng nhập của bạn không hợp lệ, vui lòng đăng nhập lại.'
        )
        return
      }
      var message = {
        type: 'text',
        userid: this.userInfo.id,
        username: this.userInfo.username,
        userphone: this.formatMobile(this.userInfo.userphone),
        userimg: this.userInfo.userimg,
        text: speakText,
      }
      const IM = this.IM
      // 注: im 实例通过 RongIMLib.init 获取(单mộtสถานที่页面仅需初始化一次)
      var chatRoom = IM.ChatRoom.get({
        id: this.lottery.roomid,
      })
      chatRoom
        .send({
          messageType: RongIMLib.MESSAGE_TYPE.TEXT, // 'RC:TxtMsg'
          content: message,
        })
        .then(function (message) {
          // console.log('发送文字消息成功', message)
        })
      const {
        data: { ret },
      } = await saveMessage({
        type: this.lottery.type,
        message: message,
      })
      if (ret === 1) {
        this.$refs.chatRoom.getHistoryMessage()
        this.text = ''
      }
    },

    // 点击more里面的每一mộtสถานที่选项
    HanddleClickitem(action) {
      if (action.text === 'Kiểu chơi') {
        this.$router.push({
          path: '/gameShows',
          query: { title: 'Kiểu chơi', lotteryType: 'method' },
        })
      } else if (action.text === 'tỷ lệ cược') {
        this.$router.push({
          path: '/gameShows',
          query: { title: 'tỷ lệ cược', lotteryType: 'odds' },
        })
      } else if (action.text === 'Dịch vụ khách hàng') {
        this.isShowPopup = true
        window.location.href = this.serviceURL
      } else if (action.text === 'Lịch sử đặt cược') {
        if (this.$route.query.key) {
          if (!this.appLinkClick) {
            this.$toast(
              'đăng nhập không đúng Không thể sử dụng chức năng này. Vui lòng liên hệ với dịch vụ Bạn vẫn chưa đăng ký. Hãy bắt đầu.'
            )
            return false
          }
        }
        if (!NotLoginLinkPage()) {
          this.$router.push('/login')
          return false
        }
        this.$router.push({
          path: '/single',
          query: { title: this.lottery.title, type: this.lottery.type },
        })
      }
    },
    handleRoomSeed() {
      if (this.$route.query.key) {
        if (!this.appLinkClick) {
          this.$toast(
            'đăng nhập không đúng Không thể sử dụng chức năng này. Vui lòng liên hệ với dịch vụ Bạn vẫn chưa đăng ký. Hãy bắt đầu.'
          )
          return false
        }
      }
      if (!NotLoginLinkPage()) {
        this.$router.push('/login')
        return false
      }
      this.showRoomSeed = true
    },
    handleRecharge() {
      if (this.$route.query.key) {
        if (!this.appLinkClick) {
          this.$toast(
            'đăng nhập không đúng Không thể sử dụng chức năng này. Vui lòng liên hệ với dịch vụ Bạn vẫn chưa đăng ký. Hãy bắt đầu.'
          )
          return false
        }
      }
      if (!NotLoginLinkPage()) {
        this.$router.push('/login')
        return false
      }
      this.$router.push('/recharge')
    },
    async getLottery() {
      const {
        data: { data, ret },
      } = await request({
        method: 'get',
        url: 'lottery/setting',
      })
      if (ret === 1) this.lotteryList = data
      this.getUserInfo()
      const list = this.lotteryList.filter((item) => {
        return item.type === this.$route.query.type
      })
      if (!list.length) {
        Dialog.alert({
          title: 'Lưu ý',
          message:
            'đăng thông tin nhập không hợp lệ Vui lòng đăng xuất và nhập lại.',
        }).then(() => {})
        return false
      }
      this.lottery = list[0]
      localStorage.setItem(
        'APP_LINK_LOTTERY_ITEM',
        JSON.stringify(this.lottery)
      )
      this.$store.commit('setLottery', this.lottery)
      this.initRong()
    },
    async judgment() {
      const {
        data: { data, ret, msg },
      } = await request({
        method: 'get',
        url: 'users/key/login',
        params: {
          key: this.$route.query.key,
        },
      })
      if (ret === 1) {
        this.$store.commit('setUser', data.token)
        this.appLinkClick = true
      } else {
        Dialog.alert({
          title: 'Lưu ý',
          message: msg,
        }).then(() => {})
      }
    },
    getAllInfo() {
      const timer1 = window.setInterval(() => {
        setTimeout(() => {
          this.newGetAllInfo()
        }, 0)
      }, 5000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer1)
      })
    },

    async newGetAllInfo() {
      const {
        data: { data, ret, msg },
      } = await request({
        method: 'get',
        url: 'lottery/crontab',
        params: {
          type: this.$route.query.type || this.lottery.type,
        },
      })
      if (ret === 1) {
        const userInfo = data.user
        let list = data.message.map((_) => {
          return JSON.parse(_)
        })
        this.userMoney = userInfo ? userInfo.money : 0
        if (list.length) {
          let oldList = this.messageLists
            .slice(-10, this.messageLists.length)
            .map((item) => {
              return item
            })
          let len = oldList.length ? oldList.length - 1 : 0
          if (!len) {
            return false
          }
          list.forEach((_) => {
            if (
              _.type !== oldList[len].type &&
              _.lottery.method !== oldList[len].item.lottery.method &&
              _.lottery.code !== oldList[len].lottery.code &&
              _.lottery.expect !== oldList[len].lottery.expect &&
              _.lottery.totalMoney !== oldList[len].lottery.totalMoney &&
              _.lottery.number !== oldList[len].lottery.number
            ) {
              this.messageLists.push(_)
              this.$refs.chatRoom.messageLists.push(_)
            }
          })
        }
      } else {
        Dialog.alert({
          title: 'Lưu ý',
          message: msg,
        }).then(() => {})
      }
    }, // 获取后面的新接口返回的三类

    /** 其他 **/
    openDwd(item) {
      if (count(item) === 0) {
        return ['chờ xổ số']
      }
      item = item.dwd
      if (count(item) === 0) {
        return ['chờ xổ số']
      }
      if (this.type === 'hk6hc' || this.type === 'js6hc') {
        item = delLast(item)
      }
      return item
    },
    openZh(item) {
      if (count(item) === 0) {
        return ''
      }
      if (
        this.type === 'bjsc' ||
        this.type === 'xyft' ||
        this.type === 'sfpk10'
      ) {
        return ''
      }
      if (this.type === 'hk6hc' || this.type === 'js6hc') {
        item = item.dwd
        if (count(item) === 0) {
          return ''
        }
        return 'Mã đặc biệt ：' + last(item)
      }
      item = item.zh
      if (count(item) === 0) {
        return ''
      }
      if (typeof item.zh === 'undefined') {
        return ''
      }
      return 'Toàn bộ:' + item.zh
    },
  },
  computed: {
    title() {
      if (this.lottery.title === 'Fast') {
        return ''
      }
    },
    isShowBack() {
      if (this.$route.query.key) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>

<style lang="less" scoped>
.van-collapse-item {
  z-index: 9;
}
.order {
  padding: 1px;
  /deep/ .van-popover__action {
    padding: 0;
  }
  .nav-bar {
    width: 100%;
    position: fixed;
    // z-index: 9999;
  }
  .order-title-new {
    width: 100%;
    background-color: #fff;
    margin-top: 1.22667rem;
    > .order-title-label-list {
      display: flex;
      align-items: center;
      font-size: 30px;
      border-bottom: 1px solid #f2f2f2;
      > .order-title-label-item {
        width: 33.33%;
        height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #f2f2f2;
        font-weight: 600;
        p {
          color: #dc1323;
        }
      }
      > .order-title-label-item:nth-last-child(1) {
        border: 0;
      }
    }
  }
  .lottery-list-item {
    /deep/ .van-cell {
      padding: 0;
      border-bottom: 1px solid #f2f2f2;
      display: flex;
      align-items: center;
    }
    /deep/ .van-collapse-item__content {
      padding: 0;
    }
    /deep/ .van-cell__right-icon {
      padding-right: 20px;
    }
    .list {
      // padding-right: 40px;
      height: 400px;
      overflow: auto;
    }
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'PingFang-Medium';
      padding: 15px 0;
      span {
      }
      > .number:nth-last-child(1) {
        padding-left: 10px;
      }

      .van-cell__right-icon {
        padding-right: 20px;
      }

      .medium {
        font-size: 24px;
      }
      .number {
        font-size: 30px;
      }
    }
    .item2 {
      justify-content: space-between;
      padding: 15px 30px;
      > .item-history-left {
        display: flex;
        align-items: center;
        > .number-open-code-box {
          display: flex;
          align-items: center;
          border-left: 1px solid #f2f2f2;
          padding-left: 10px;
          margin-left: 10px;
          > span {
            color: #dc1626;
            margin-right: 3px;
          }
        }
      }
      > .history-right {
        color: #20b21e;
      }
    }
    .result-color {
      width: 20px;
      height: 20px;
      display: block;
      border-radius: 50px;
      margin-right: 4px;
    }
    .qiquiu {
      width: 60px;
      height: 60px;
    }
  }
  .order-title {
    width: 100%;
    // position: fixed;
    z-index: 2;
    // top: 1.22667rem;
  }
  .chatRoom {
    height: calc(100% - 384px - 30px);
  }
  height: 100%;
  font-family: 'PingFang-Regular';
  position: relative;
  box-sizing: border-box;
  .van-nav-bar {
    /deep/ .van-nav-bar__content {
      // background-color: #ff0000;
    }
    /deep/ .van-nav-bar__title {
      // color: #fff;
    }
    button {
      background-color: #eeeeee;
      border: none;
      border-radius: 5px;
      color: rgb(51, 20, 20);
      font-size: 34px;
    }
    .iconfont {
      font-size: 44px;
      // color: #fff;
    }
  }

  .van-cell-group {
    margin: 15px 10px 0;
    .van-cell {
      padding: 0;
    }
    .balance {
      display: flex;
      align-items: center;
      .balance-left-money {
        font-size: 36px;
        color: #ff0000;
        text-align: center;
      }
      /deep/ .van-cell__value {
        text-align: center;
      }
    }

    .period {
      padding-top: 5px;
      .period-left-number {
        font-size: 28px;
        color: #000;
        text-align: center;
      }
      .period-right-time {
        text-align: center;
        font-size: 30px;
        color: #000;
      }
    }
  }

  .title {
    text-align: center;
    font-size: 24px;
    color: #858585;
  }
  .lottery-box {
    padding-top: 5px;
    margin: 0 10px;
    background-color: #fff;
    span {
      text-align: center;
      &:first-child {
        // width: 34%;
        width: 42%;
      }
      // &:nth-child(2) {
      //   width: 22%;
      // }
      &:nth-child(2) {
        // width: 22%;
        width: 31%;
      }
      &:nth-child(3) {
        // width: 22%;
        width: 31%;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          margin-top: 15px;
        }
      }
    }
    .lottery-list {
      padding-right: 30px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .lottery-list-item {
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #eeeeee;
        font-family: 'PingFang-Medium';
        span {
          padding-left: 30px;
        }

        .van-cell__right-icon {
          padding-right: 20px;
        }

        .medium {
          font-size: 24px;
        }
        .number {
          font-size: 30px;
        }
      }
      .result-color {
        width: 20px;
        height: 20px;
        display: block;
        border-radius: 50px;
        margin-right: 4px;
      }
      .qiquiu {
        width: 60px;
        height: 60px;
      }
    }

    .van-collapse {
      /deep/ .van-cell {
        padding: 0 !important;
      }
      /deep/ .van-collapse-item__content {
        padding: 0;
      }
    }
    .lottery-list-item {
      .list {
        padding-right: 40px;
        height: 400px;
        overflow: auto;
      }
    }
  }

  .footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    .footer-chat {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 33.33%;
      height: 84px;
      box-sizing: border-box;
      padding: 10px;
      // border-radius: 37px;
      // background-color: #ededed;
      text-align: center;
      font-size: 40px;
      color: #228200;
      img {
        height: 41px;
        margin-right: 5px;
      }
    }
    .footer-play {
      height: 84px;
      background-image: url('../../assets/images/new-img/playnow.png');
      background-size: cover;
      font-size: 40px;
      color: #fff;
      line-height: 84px;
      text-align: center;
    }
  }

  .lottery-confirm-new {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99999;
  }
  .lottery-confirm-main-new {
    width: 584px;
    background-color: #ffffff;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .confirm-head-new {
    height: 98px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .confirm-bar-new {
    width: 584px;
    height: 98px;
    line-height: 98px;
    color: #fff;
    background-color: #13cc00;
    text-align: center;
    align-items: center;
    font-size: 32px;
  }
  .lottery-confirm-mian-cont {
    width: 584px;
    background-color: #fff;
  }
  .lottery-confirm-mian-cont-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .lottery-confirm-mian-cont-select-text {
    font-size: 30px;
    color: #838383;
    height: 68px;
    line-height: 68px;
  }
  .lottery-confirm-mian-cont-select-kk {
    width: 516px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 34px;
  }
  .lottery-confirm-mian-cont-select-key {
    width: 120px;
    height: 70px;
    font-size: 34px;
    color: #ff0000;
    border-radius: 6px;
    border-style: solid;
    border-width: 1px;
    border-color: #838383;
    text-align: center;
    line-height: 70px;
  }
  .lottery-confirm-mian-cont-select-key-on {
    color: #fff;
    background-color: #ffcf0e;
    border-color: #ffcf0e;
  }
  .lottery-confirm-mian-cont-input-box,
  .lottery-confirm-mian-money-show-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .lottery-confirm-mian-cont-input {
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .lottery-confirm-mian-money-show-box {
    flex-direction: row;
  }
  .cont-input-text {
    font-size: 60px;
    color: #323232;
    margin-right: 15px;
    margin: 28px 0;
  }
  .cont-input {
    width: 272px;
    height: 70px;
    font-size: 40px;
    border: 0;
    border-bottom: 1px solid #000;
    color: #323232;
    text-align: center;
  }
  .lottery-confirm-mian-cont-show-money {
    font-size: 48px;
    color: #fe0000;
    // margin: 5px 0 35px;
  }
  .lottery-confirm-mian-money-checkbox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .lottery-confirm-mian-money-checkbox-fake-box {
    width: 516px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 34px;
  }
  .fake-check {
    width: 25px;
    height: 25px;
    border-style: solid;
    border-width: 1px;
    border-color: #838383;
    border-radius: 4px;
  }
  .check-icon {
    width: 25px;
    height: 25px;
  }
  .check-text1 {
    font-size: 24px;
    color: #838383;
  }
  .check-text2 {
    font-size: 24px;
    color: #0066ff;
  }
  .lottery-confirm-mian-btn-box {
    width: 584px;
    flex-direction: row;
    margin-top: 16px;
  }
  .lottery-confirm-mian-btn-box-colse {
    width: 292px;
    height: 88px;
    font-size: 28px;
    color: #666;
    border: 0;
  }
  .lottery-confirm-mian-btn-box-confirm {
    width: 292px;
    height: 88px;
    border: 0;
    font-size: 28px;
    color: #fff;
  }
  .chat {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    align-items: center;
    .van-button {
      width: 15%;
      margin-right: 15px;
    }
    .gray {
      background-color: gray;
    }
    input {
      padding: 0;
      margin: 0;
      border: 0;
      width: 70%;
      border-bottom: 1px solid blue;
      font-size: 32px;
    }
  }
}

.lottery-send {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.lottery-confirm {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}
.lottery-confirm-main {
  width: 700px;
  margin: auto;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.redpacket {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}
.redpacket-info {
  position: relative;
  align-items: center;
  width: 540px;
  background-color: #d95942;
  border-radius: 12px;
  overflow: hidden;
}
.redpacket-info-close {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 80px;
  height: 80px;
  color: #963d2b;
  content: 'tb-close';
}
.redpacket-info-userimg {
  margin-top: 92px;
  width: 80px;
  height: 80px;
  border-radius: 6px;
  overflow: hidden;
}
.redpacket-info-userphone {
  margin-top: 16px;
  font-size: 30px;
  color: #ffecc0;
}
.redpacket-info-money {
  margin-top: 12px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 26px;
  color: #ffecc0;
  text-overflow: ellipsis;
  lines: 1;
}
.redpacket-info-message {
  margin-top: 32px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 42px;
  color: #ffecc0;
  text-overflow: ellipsis;
  lines: 2;
}
.redpacket-info-open {
  position: absolute;
  bottom: 162px;
  left: 180px;
  width: 180px;
  height: 180px;
  border-radius: 90px;
}
.redpacket-info-bg {
  width: 540px;
  height: 380px;
}
.confirm-head {
  position: relative;
  width: 700px;
  height: 88px;
}
.confirm-bar {
  width: 640px;
  width: 100%;
  height: 88px;
  line-height: 88px;
  color: #242424;
  background-color: #fdce45;
  text-align: center;
  align-items: center;
  font-size: 32px;
}
.confirm-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 88px;
  height: 88px;
  line-height: 88px;
  color: #dc1323;
  font-size: 52px;
  content: 'tb-round-close-fill';
}
.confirm-odds {
  width: 100%;
  height: 65px;
  background-color: #f4f8fb;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.confirm-odds-title {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 26px;
  color: #666666;
}
.confirm-odds-info {
  flex: 1;
  height: 65px;
  font-size: 26px;
  color: #666666;
  margin-right: 20px;
  background-color: #f4f8fb;
}
.confirm-inbox {
  width: 640px;
  background-color: #ffffff;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.confirm-inbox-text {
  font-size: 29px;
  color: #555555;
}
.confirm-inbox-input {
  margin-left: 12px;
  margin-right: 12px;
  border-bottom-color: #cccccc;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: flex;
  align-items: center;
}
.confirm-inbox-inputi {
  width: 120px;
  height: 54px;
  text-align: center;
  font-size: 36px;
  color: #dc1323;
  border: 0;
}
.confirm-inbox-btn,
.confirm-inbox-btn-act {
  width: 72px;
  height: 56px;
  line-height: 56px;
  font-size: 28px;
  text-align: center;
  color: #232323;
  background-color: #f4f8fb;
}
.confirm-inbox-btn-act {
  color: #ffffff;
  background-color: #dc1323;
}
.confirm-item {
  width: 640px;
  height: 50px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.confirm-item-title {
  color: #666666;
  font-size: 26px;
}
.confirm-item-value {
  color: #dc1323;
  font-size: 32px;
  margin-left: 6px;
  margin-right: 6px;
}
.confirm-item-subtitle {
  flex: 1;
  height: 50px;
  color: #666666;
  font-size: 22px;
  margin-left: 6px;
}
.confirm-foot {
  width: 100%;
  // background-color: #dc1323;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* padding-top: 30px; */
  margin-top: 30px;
}
.confirm-foot-button1,
.confirm-foot-button2 {
  flex: 1;
  height: 90px;
  border-radius: 0;
  font-size: 28px;
}
.confirm-foot-button2 {
  width: 100%;
  background-color: #dc1323;
  color: #fff;
  border: 0;
}
</style>
