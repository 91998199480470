<template>
  <div class="list">
    <div class="list" v-for="pos in [0]" :key="pos">
      <div class="item">
        <div class="item-right">
          <div class="item-row">
            <div
              v-for="(code, num) in ['màu đỏ', 'xanh nhạt', 'màu xanh lá']"
              :key="num"
              @click="select(pos, num, code)"
              :class="[codes[pos][num] === code ? 'item-action' : 'item-code']"
            >
              <span
                :class="[
                  codes[pos][num] === code
                    ? 'item-code-action'
                    : 'item-code-code',
                ]"
                :style="{
                  color: codes[pos][num] === code ? '#ffffff' : codeColor(code),
                }"
                >{{ code }}คลื่น</span
              >
              <span
                :class="[
                  codes[pos][num] === code
                    ? 'item-num-action'
                    : 'item-num-code',
                ]"
                >{{ code | codeNum }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.list {
  flex: 1;
  padding-top: 50px;
}

.item {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 36px;
  padding-right: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f4f8fb;
}

.item-right {
  flex: 1;
}

.item-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.item-code,
.item-action {
  /* width: 158px; */
  width: 190px;
  height: 168px;
  border-radius: 12px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-action {
  background-color: #dc1323;
}

.item-code-action,
.item-code-code {
  color: #dc1323;
  font-size: 34px;
  padding-top: 6px;
}

.item-code-action {
  color: #ffffff;
}

.item-num-action,
.item-num-code {
  color: #777777;
  line-height: 24px;
  font-size: 20px;
  padding-top: 6px;
  padding-left: 12px;
  padding-right: 12px;
  text-align: center;
}

.item-num-action {
  color: #ffffff;
}
</style>

<script>
export default {
  name: 'RoomSendLhcTmsb',

  props: {
    value: {
      //   type: Object,
      //   default: [],
    },
    maxnum: {
      default: 0,
    },
  },

  filters: {
    codeNum(code) {
      switch (code) {
        case 'màu đỏ':
          return '01 02 07 08 12 13 18 19 23 24 29 30 34 35 40 45 46'

        case 'xanh nhạt':
          return '03 04 09 10 14 15 20 25 26 31 36 37 41 42 47 48'

        case 'màu xanh lá':
          return '05 06 11 16 17 21 22 27 28 32 33 38 39 43 44 49'
      }
    },
  },

  data() {
    return {
      codes: [[]],
    }
  },

  mounted() {},

  watch: {
    value(val) {
      if (val === '') {
        const newValue = []
        for (let i = 0; i < this.codes.length; i++) {
          newValue.push([])
        }
        this.codes = newValue
        this.$emit('input', this.codes)
      }
    },
  },

  methods: {
    codeColor(code) {
      switch (code) {
        case 'màu xanh lá':
          return '#4CAF50'
        case 'xanh nhạt':
          return '#2196F3'
        case 'màu đỏ':
          return '#DC1323'
      }
    },

    select(pos, num, code) {
      this.$set(this.codes[pos], num, this.codes[pos][num] !== code ? code : '')
      this.$emit('input', this.codes)
    },
  },
}
</script>
