<template>
  <div class="list">
    <div class="list" v-for="pos in [0]" :key="pos">
      <div class="item">
        <div class="item-right">
          <div class="item-row">
            <span
              v-for="(code, num) in ['Tài', 'Xỉu', 'Lẻ', 'Chẵn']"
              :key="num"
              @click="select(pos, num, code)"
              :class="[codes[pos][num] === code ? 'item-action' : 'item-code']"
              >{{ code }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.list {
  flex: 1;
  padding-top: 50px;
}

.item {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 36px;
  padding-right: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f4f8fb;
}

.item-right {
  flex: 1;
}

.item-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.item-code,
.item-action {
  width: 110px;
  height: 110px;
  line-height: 110px;
  border-radius: 55px;
  font-size: 36px;
  text-align: center;
  color: #dc1323;
  background-color: #ffffff;
}

.item-action {
  color: #ffffff;
  background-color: #dc1323;
}
</style>

<script>
export default {
  name: 'RoomSendPk10Gyh',

  props: {
    value: {
      //   type: Object,
      //   default: [],
    },
    maxnum: {
      default: 0,
    },
  },

  filters: {},

  data() {
    return {
      codes: [[]],
    }
  },

  mounted() {},

  watch: {
    value(val) {
      if (val === '') {
        const newValue = []
        for (let i = 0; i < this.codes.length; i++) {
          newValue.push([])
        }
        this.codes = newValue
        this.$emit('input', this.codes)
      }
    },
  },

  methods: {
    select(pos, num, code) {
      this.$set(this.codes[pos], num, this.codes[pos][num] !== code ? code : '')
      this.$emit('input', this.codes)
    },
  },
}
</script>
