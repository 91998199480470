<template>
  <div class="list">
    <div v-for="pos in [0, 1]" :key="pos">
      <div class="item">
        <span class="item-title">{{
          ['cuộc tổng tuyển cử', 'Lẻ lựa chọn'][pos]
        }}</span>
        <div class="item-line"></div>
        <div class="item-right">
          <div class="item-row">
            <div
              v-for="(code, num) in [
                ['cuộc tổng tuyển cử'],
                ['111', '222', '333', '444', '555', '666'],
              ][pos]"
              :key="num"
              v-show="getOdds(oddsName(pos)) > 0"
              @click="select(pos, num, code)"
            >
              <span
                :class="[
                  codes[pos][num] === code ? 'item-action' : 'item-code',
                ]"
                >{{ code }}</span
              >
              <span class="item-odds">{{ getOdds(oddsName(pos)) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.list {
  flex: 1;
  padding-top: 50px;
}

.item {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 36px;
  padding-right: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f4f8fb;
}

.line {
  flex: 1;
  height: 1px;
  background-color: #e4e4e4;
}

.item-title {
  font-size: 28px;
  color: #999999;
}

.item-line {
  margin-left: 36px;
  margin-right: 36px;
  width: 1px;
  height: 60px;
  background-color: #e4e4e4;
}

.item-right {
  flex: 1;
}

.item-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.item-row div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-code,
.item-action {
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 45px;
  font-size: 28px;
  text-align: center;
  color: #dc1323;
  background-color: #ffffff;
}

.item-action {
  color: #ffffff;
  background-color: #dc1323;
}

.item-odds {
  width: 80px;
  margin-top: 8px;
  text-align: center;
  font-size: 20px;
  padding: 4px 0;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #dddddd;
  background-color: #ffffff;
  color: #888888;
}
</style>

<script>
export default {
  name: 'RoomSendK3Sth',

  props: {
    value: {
      //   type: Object,
      //   default: [],
    },
    oddsLists: {
      //   type: Object,
      //   default: {},
    },
  },

  data() {
    return {
      codes: [[], []],
    }
  },

  mounted() {},

  methods: {
    oddsName(pos) {
      return ['sth-tx-tx', 'sth-dx-dx'][pos]
    },

    getOdds(type) {
      const item = this.oddsLists.find((item) => {
        return item.method == type
      })
      return item ? item.odds : 0
    },

    select(pos, num, code) {
      this.$set(this.codes[pos], num, this.codes[pos][num] !== code ? code : '')
      this.$emit('select', this.oddsName(pos), [this.codes[pos]])
    },
  },
}
</script>
