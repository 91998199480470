var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list",attrs:{"pullTips":"false"}},_vm._l(([0]),function(pos){return _c('div',{key:pos},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-right"},[_c('div',{staticClass:"item-row"},_vm._l((['con chuột', 'con bò', 'con hổ']),function(code,num){return _c('div',{key:num,class:[_vm.codes[pos][num] === code ? 'item-action' : 'item-code'],on:{"click":function($event){return _vm.select(pos, num, code)}}},[_c('span',{class:[
                _vm.codes[pos][num] === code
                  ? 'item-code-action'
                  : 'item-code-code' ]},[_vm._v(_vm._s(code))]),_c('span',{class:[
                _vm.codes[pos][num] === code
                  ? 'item-num-action'
                  : 'item-num-code' ]},[_vm._v(_vm._s(_vm._f("codeNum")(code)))])])}),0),_c('div',{staticClass:"item-space"}),_c('div',{staticClass:"item-row"},_vm._l((['Con thỏ', 'Rồng', 'con rắn']),function(code,num){return _c('div',{key:num,class:[
              _vm.codes[pos][num + 3] === code ? 'item-action' : 'item-code' ],on:{"click":function($event){return _vm.select(pos, num + 3, code)}}},[_c('span',{class:[
                _vm.codes[pos][num + 3] === code
                  ? 'item-code-action'
                  : 'item-code-code' ]},[_vm._v(_vm._s(code))]),_c('span',{class:[
                _vm.codes[pos][num + 3] === code
                  ? 'item-num-action'
                  : 'item-num-code' ]},[_vm._v(_vm._s(_vm._f("codeNum")(code)))])])}),0),_c('div',{staticClass:"item-space"}),_c('div',{staticClass:"item-row"},_vm._l((['ngựa', 'con cừu', 'con khỉ']),function(code,num){return _c('div',{key:num,class:[
              _vm.codes[pos][num + 6] === code ? 'item-action' : 'item-code' ],on:{"click":function($event){return _vm.select(pos, num + 6, code)}}},[_c('span',{class:[
                _vm.codes[pos][num + 6] === code
                  ? 'item-code-action'
                  : 'item-code-code' ]},[_vm._v(_vm._s(code))]),_c('span',{class:[
                _vm.codes[pos][num + 6] === code
                  ? 'item-num-action'
                  : 'item-num-code' ]},[_vm._v(_vm._s(_vm._f("codeNum")(code)))])])}),0),_c('div',{staticClass:"item-space"}),_c('div',{staticClass:"item-row"},_vm._l((['gà con', 'chú chó', 'lợn']),function(code,num){return _c('div',{key:num,class:[
              _vm.codes[pos][num + 9] === code ? 'item-action' : 'item-code' ],on:{"click":function($event){return _vm.select(pos, num + 9, code)}}},[_c('span',{class:[
                _vm.codes[pos][num + 9] === code
                  ? 'item-code-action'
                  : 'item-code-code' ]},[_vm._v(_vm._s(code))]),_c('span',{class:[
                _vm.codes[pos][num + 9] === code
                  ? 'item-num-action'
                  : 'item-num-code' ]},[_vm._v(_vm._s(_vm._f("codeNum")(code)))])])}),0)])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }