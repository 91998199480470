<template>
  <scroll-view class="list" pullTips="false">
    <div v-for="pos in [0]" :key="pos">
      <div class="item">
        <div class="item-right">
          <div class="item-row">
            <text
              v-for="(code, num) in ['01', '02', '03', '04', '05']"
              :key="num"
              @click="select(pos, num, code)"
              :class="[codes[pos][num] === code ? 'item-action' : 'item-code']"
              >{{ code }}
            </text>
          </div>
          <div class="item-space"></div>
          <div class="item-row">
            <text
              v-for="(code, num) in ['06', '07', '08', '09', '10']"
              :key="num"
              @click="select(pos, num + 5, code)"
              :class="[
                codes[pos][num + 5] === code ? 'item-action' : 'item-code',
              ]"
              >{{ code }}
            </text>
          </div>
          <div class="item-space"></div>
          <div class="item-row">
            <text
              v-for="(code, num) in ['11', '12', '13', '14', '15']"
              :key="num"
              @click="select(pos, num + 10, code)"
              :class="[
                codes[pos][num + 10] === code ? 'item-action' : 'item-code',
              ]"
              >{{ code }}
            </text>
          </div>
          <div class="item-space"></div>
          <div class="item-row">
            <text
              v-for="(code, num) in ['16', '17', '18', '19', '20']"
              :key="num"
              @click="select(pos, num + 15, code)"
              :class="[
                codes[pos][num + 15] === code ? 'item-action' : 'item-code',
              ]"
              >{{ code }}
            </text>
          </div>
          <div class="item-space"></div>
          <div class="item-row">
            <text
              v-for="(code, num) in ['21', '22', '23', '24', '25']"
              :key="num"
              @click="select(pos, num + 20, code)"
              :class="[
                codes[pos][num + 20] === code ? 'item-action' : 'item-code',
              ]"
              >{{ code }}
            </text>
          </div>
          <div class="item-space"></div>
          <div class="item-row">
            <text
              v-for="(code, num) in ['26', '27', '28', '29', '30']"
              :key="num"
              @click="select(pos, num + 25, code)"
              :class="[
                codes[pos][num + 25] === code ? 'item-action' : 'item-code',
              ]"
              >{{ code }}
            </text>
          </div>
          <div class="item-space"></div>
          <div class="item-row">
            <text
              v-for="(code, num) in ['31', '32', '33', '34', '35']"
              :key="num"
              @click="select(pos, num + 30, code)"
              :class="[
                codes[pos][num + 30] === code ? 'item-action' : 'item-code',
              ]"
              >{{ code }}
            </text>
          </div>
          <div class="item-space"></div>
          <div class="item-row">
            <text
              v-for="(code, num) in ['36', '37', '38', '39', '40']"
              :key="num"
              @click="select(pos, num + 35, code)"
              :class="[
                codes[pos][num + 35] === code ? 'item-action' : 'item-code',
              ]"
              >{{ code }}
            </text>
          </div>
          <div class="item-space"></div>
          <div class="item-row">
            <text
              v-for="(code, num) in ['41', '42', '43', '44', '45']"
              :key="num"
              @click="select(pos, num + 40, code)"
              :class="[
                codes[pos][num + 40] === code ? 'item-action' : 'item-code',
              ]"
              >{{ code }}
            </text>
          </div>
          <div class="item-space"></div>
          <div class="item-row">
            <text
              v-for="(code, num) in ['46', '47', '48', '49']"
              :key="num"
              @click="select(pos, num + 45, code)"
              :class="[
                codes[pos][num + 45] === code ? 'item-action' : 'item-code',
              ]"
              >{{ code }}
            </text>
            <div class="item-none"></div>
          </div>
        </div>
      </div>
    </div>
  </scroll-view>
</template>

<style scoped>
.list {
  flex: 1;
}

.item {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 36px;
  padding-right: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f4f8fb;
}

.item-space {
  flex: 1;
  height: 20px;
}

.item-right {
  flex: 1;
}

.item-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.item-code,
.item-action,
.item-none {
  width: 76px;
  height: 76px;
  line-height: 76px;
  border-radius: 38px;
  font-size: 30px;
  text-align: center;
  color: #dc1323;
  background-color: #ffffff;
}

.item-action {
  color: #ffffff;
  background-color: #dc1323;
}

.item-none {
  background-color: rgba(0, 0, 0, 0);
}
</style>

<script>
import { each } from '@/utils/unique'
export default {
  name: 'RoomSendLhcEqz',

  props: {
    value: {
      // type: Object,
      // default: [],
    },
    maxnum: {
      default: 0
    }
  },

  filters: {},

  data () {
    return {
      codes: [[]]
    }
  },

  mounted () {},

  watch: {
    value (val) {
      if (val === '') {
        const newValue = []
        for (let i = 0; i < this.codes.length; i++) {
          newValue.push([])
        }
        this.codes = newValue
        this.$emit('input', this.codes)
      }
    }
  },

  methods: {
    select (pos, num, code) {
      if (this.codes[pos][num] !== code) {
        let selectNum = 0
        each(this.codes[pos], (index, item) => {
          if (typeof item !== 'undefined' && item !== '' && item !== null) { selectNum++ }
        })
        if (selectNum >= 7) {
          this.$toast('最多可选7một nơi球')
          return
        }
      }
      this.$set(this.codes[pos], num, this.codes[pos][num] !== code ? code : '')
      this.$emit('input', this.codes)
    }
  }
}
</script>
