<template>
  <div class="list">
    <div class="list" v-for="pos in [0]" :key="pos">
      <span class="title">Chọn 2 số để tạo ghi chú.</span>
      <div class="item">
        <span class="item-title">{{ pos | unit }}</span>
        <div class="item-line"></div>
        <div class="item-right">
          <div class="item-row">
            <span
              v-for="(code, num) in ['01', '02', '03', '04', '05', '06']"
              :key="num"
              @click="select(pos, num, code)"
              :class="[codes[pos][num] === code ? 'item-action' : 'item-code']"
              >{{ code }}
            </span>
          </div>
          <div class="item-space"></div>
          <div class="item-row">
            <span
              v-for="(code, num) in ['07', '08', '09', '10', '11']"
              :key="num"
              @click="select(pos, num + 6, code)"
              :class="[
                codes[pos][num + 6] === code ? 'item-action' : 'item-code',
              ]"
              >{{ code }}
            </span>
            <span class="item-none"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.list {
  flex: 1;
  padding-top: 50px;
}

.title {
  color: #888888;
  font-size: 26px;
  margin-bottom: 20px;
  text-align: center;
  display: block;
}

.item {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 36px;
  padding-right: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f4f8fb;
}

.item-title {
  font-size: 28px;
  color: #999999;
}

.item-line {
  margin-left: 36px;
  margin-right: 36px;
  width: 1px;
  height: 60px;
  background-color: #e4e4e4;
}

.item-space {
  flex: 1;
  height: 16px;
}

.item-right {
  flex: 1;
}

.item-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.item-code,
.item-action,
.item-none {
  width: 72px;
  height: 72px;
  line-height: 72px;
  border-radius: 36px;
  font-size: 28px;
  text-align: center;
  color: #dc1323;
  background-color: #ffffff;
}

.item-action {
  color: #ffffff;
  background-color: #dc1323;
}

.item-none {
  background-color: rgba(0, 0, 0, 0);
}
</style>

<script>
export default {
  name: 'RoomSendX5Hezux',

  props: {
    value: {
      //   type: Object,
      //   default: [],
    },
    maxnum: {
      default: 0,
    },
  },

  filters: {
    unit(pos) {
      return ['选号'][pos]
    },
  },

  data() {
    return {
      codes: [[]],
    }
  },

  mounted() {},

  watch: {
    value(val) {
      if (val === '') {
        let newValue = []
        for (let i = 0; i < this.codes.length; i++) {
          newValue.push([])
        }
        this.codes = newValue
        this.$emit('input', this.codes)
      }
    },
  },

  methods: {
    select(pos, num, code) {
      this.$set(this.codes[pos], num, this.codes[pos][num] !== code ? code : '')
      this.$emit('input', this.codes)
    },
  },
}
</script>
